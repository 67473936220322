import { Component, OnInit, OnDestroy, inject } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import * as _ from "lodash";

import { Constants } from "../../../constants/constants";
import { SharedService } from "src/app/services/shared.service";
import { TargetsService } from "../targets.service";

import { NdiTarget, TargetApiType } from "../../channels/channel";
import { ModalService } from "../../../components/shared/modals/modal.service";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TitleCasePipe } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../../services/title.service";
import { UntypedFormControl, Validators } from "@angular/forms";
import { urlBuilder } from "@zixi/shared-utils";
@Component({
    selector: "app-target-ndi-form",
    templateUrl: "./target-ndi-form.component.html",
    providers: [TitleCasePipe]
})
export class TargetNdiFormComponent implements OnInit, OnDestroy {
    target: NdiTarget;
    targetId: number;
    targetName: string;
    targetNames: string[];
    existingTarget: NdiTarget;
    action: string;

    type: string;
    subtype: string;
    id: string;

    ingestURLs: string[];

    loading = true;
    saving = false;

    submitted = false;
    minLength = 2;
    isEdit = false;
    isClone = false;
    startDisabled = false;
    constants = Constants;

    private targetsSubscription: Subscription;

    selectedChannelID: number | null = null;

    tagsControl = new UntypedFormControl([], [Validators.required]);
    nameControl = new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern(Constants.validators.source_name),
        Validators.pattern(Constants.validators.no_blanc_start_or_end)
    ]);
    private route = inject(ActivatedRoute);
    private router = inject(Router);
    private sharedService = inject(SharedService);
    private modalService = inject(ModalService);
    private ts = inject(TargetsService);
    private mixpanelService = inject(MixpanelService);
    private translate = inject(TranslateService);
    private titleService = inject(TitleService);
    private titlecasePipe = inject(TitleCasePipe);
    private location = inject(Location);

    prepForm() {
        if (!this.target && this.action) {
            return;
        }

        if (this.action) {
            this.tagsControl.setValue(this.target.resourceTags);

            if (this.isEdit || this.isClone) {
                this.selectedChannelID = this.target.delivery_channel_id || null;
                if (this.isEdit) {
                    this.nameControl.setValue(this.target.name);
                } else {
                    this.target.name = "";
                    this.target.muted = this.target.active_mute ? 1 : 0;
                }
            }
        }

        if (!this.target && !this.isClone && !this.isEdit) {
            this.resetForm();
        }

        // Set Title
        this.titleService.setTitle(
            this.translate.instant("TARGET") +
                " - " +
                (this.action ? this.titlecasePipe.transform(this.action) : "New") +
                " " +
                (this.target && this.target.name ? this.target.name : "")
        );
    }

    resetForm() {
        this.tagsControl.setValue([]);
        this.nameControl.setValue(null);

        // Channel
        this.target = new NdiTarget();
        this.target.location = {};
    }

    async ngOnInit() {
        const params = this.route.snapshot.params;
        if (params.targetId) this.targetId = urlBuilder.decode(params.targetId);
        this.targetName = params.name;
        this.action = params.action;
        this.type = params.type;
        this.subtype = params.subtype;
        this.id = params.id;

        if (this.action === "edit") this.isEdit = true;
        if (this.action === "clone") this.isClone = true;

        let anyTarget = null;

        if (this.targetId) {
            anyTarget = Object.assign({}, this.ts.getCachedTarget(this.targetId, TargetApiType.Ndi));

            // Check if target found in cache, if not get targets and target
            if (this.sharedService.isEmptyObject(anyTarget)) {
                await this.ts.refreshTargets(TargetApiType.Ndi).toPromise();
                anyTarget = Object.assign({}, this.ts.getCachedTarget(this.targetId, TargetApiType.Ndi));
                await this.ts.refreshTarget(TargetApiType.Ndi, anyTarget.objId, true).toPromise();
                anyTarget = Object.assign({}, this.ts.getCachedTarget(this.targetId, TargetApiType.Ndi));
            } else if (!anyTarget.target.hasFullDetails) {
                await this.ts.refreshTarget(TargetApiType.Ndi, anyTarget.objId, true).toPromise();
                anyTarget = Object.assign({}, this.ts.getCachedTarget(this.targetId, TargetApiType.Ndi));
            }
        }

        if (anyTarget && anyTarget.target instanceof NdiTarget) {
            this.target = anyTarget.target;
            this.existingTarget = _.cloneDeep(this.target);
        }

        this.loading = false;

        if (this.type && this.subtype && this.id) {
            if (this.type === "channel") {
                if (this.subtype === "delivery") {
                    this.selectedChannelID = parseInt(this.id, 10);
                }
            }
        }

        this.ts.getAllTargets();

        this.targetsSubscription = this.ts.targets.subscribe(targets => {
            this.targetNames = _.map(
                _.filter(targets, t => t.apiType === TargetApiType.Ndi),
                "target.name"
            );
            this.ingestURLs = _.map(targets, "target.ingest_url");

            if (this.isEdit) {
                this.targetNames = _.without(this.targetNames, this.targetName);
            }
        });

        this.prepForm();
    }

    ngOnDestroy() {
        this.targetsSubscription.unsubscribe();
    }

    async onSubmit() {
        this.saving = true;

        const model = {
            name: this.nameControl.value,

            resource_tag_ids: _.map(this.tagsControl.value, "id"),
            alerting_profile_id: this.target.alertingProfile.id,
            type: "ndi",
            delivery_channel_id: this.selectedChannelID || null,
            location: this.target.location.address,
            muted: this.target.muted,
            is_enabled:
                !this.isEdit && this.startDisabled
                    ? 0
                    : !this.isEdit && !this.startDisabled
                    ? 1
                    : this.target.is_enabled,
            preferred_source: this.target.preferred_source
        };

        if (this.isEdit) {
            const objects = { resource_tag_ids: { objectsKey: "resourceTags", valuePath: "id" } };
            const changedData = this.sharedService.getZixiObjDiff(model, this.existingTarget, [], objects);
            const isEmptyData = this.sharedService.isEmptyObject(changedData);

            if (!isEmptyData) {
                const updatedTarget = await this.ts.updateTarget(this.existingTarget, {
                    ...changedData,
                    restart_confirmed: false
                });
                const showPopupMessageDialog = updatedTarget;
                // Restart Notice
                if (showPopupMessageDialog === true) {
                    await this.modalService.confirm(
                        "SAVE_RESTART",
                        "TARGET",
                        async () => {
                            const updateAndRestartTarget = await this.ts.updateTarget(this.target, {
                                ...changedData,
                                restart_confirmed: true
                            });
                            if (updateAndRestartTarget) {
                                this.saving = false;
                                this.mixpanelService.sendEvent("update & restart ndi target", {
                                    updated: Object.keys(changedData)
                                });
                                this.router.navigate(
                                    urlBuilder.getRegularTargetUrl(
                                        this.targetId,
                                        Constants.urls.targetTypes.ndi,
                                        model.name
                                    )
                                );
                            } else this.saving = false;
                        },
                        this.target.name
                    );
                    this.saving = false;
                } else if (updatedTarget) {
                    this.saving = false;
                    this.mixpanelService.sendEvent("update ndi target target", {
                        updated: Object.keys(changedData)
                    });
                    this.router.navigate(
                        urlBuilder.getRegularTargetUrl(this.targetId, Constants.urls.targetTypes.ndi, model.name)
                    );
                } else this.saving = false;
            } else {
                this.saving = false;
                this.router.navigate(
                    urlBuilder.getRegularTargetUrl(this.targetId, Constants.urls.targetTypes.ndi, model.name)
                );
            }
        } else {
            const result = await this.ts.addTarget(model, TargetApiType.Ndi);
            if (result) {
                this.saving = false;
                this.mixpanelService.sendEvent("create ndi target");
                this.router.navigate(
                    urlBuilder.getRegularTargetUrl(result.id, Constants.urls.targetTypes.ndi, model.name)
                );
            } else this.saving = false;
        }
    }

    cancel() {
        if (this.isEdit || this.isClone)
            this.router.navigate(
                urlBuilder.getRegularTargetUrl(this.targetId, Constants.urls.targetTypes.ndi, this.targetName)
            );
        else this.router.navigate([Constants.urls.targets]);
    }

    back() {
        this.location.back();
    }
}

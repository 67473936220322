<content-loading-animation *ngIf="loading"></content-loading-animation>
<form id="form" #form="ngForm" (ngSubmit)="form.valid && !nameControl.invalid && !tagsControl.invalid && onSubmit(form)" *ngIf="!loading">
    <div class="title-bar">
        <div class="row justify-content-center">
            <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                <div class="title">
                    <h1 *ngIf="!isClone && !isEdit">{{ "CREATE_NEW_NDI_TARGET" | translate }}</h1>
                    <h1 *ngIf="isClone">{{ "CLONE_NDI_TARGET" | translate }}</h1>
                    <h1 *ngIf="isEdit">{{ "EDIT_NDI_TARGET" | translate }}</h1>
                </div>
            </div>
        </div>
    </div>
    <div class="content-area">
        <div class="container-fluid">
            <!-- NDI -->
            <div class="row justify-content-center">
                <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                    <section id="info">
                        <div class="form-section-title">
                            <h3 class="section-title" title="{{ 'INFO' | translate }}">
                                {{ "INFO" | translate }}
                            </h3>
                        </div>

                        <!-- Name -->
                        <app-input-generic-field
                            zmid="form_name"
                            [isRestartRequiredTip]="isEdit"
                            [duplicateName]="targetNames"
                            [formControl]="nameControl"
                            [textFieldName]="'NAME' | translate"
                            [disabled]="saving"
                            [isParentFormSubmitted]="form.submitted"
                            type="text"
                        ></app-input-generic-field>

                        <!-- Access Tags -->
                        <zx-access-tags-select
                            zmid="form_access-tags"
                            [formControl]="tagsControl"
                            [label]="'TAGS' | translate"
                            name="resource_tag_ids"
                            type="delivery"
                            [isParentFormSubmitted]="form.submitted"
                        ></zx-access-tags-select>
                        <!-- Alerting Profile -->
                        <div class="form-group">
                            <label for="alertingProfile" [ngClass]="{ 'is-invalid': form.submitted && form.controls.alertingProfile?.errors }">{{
                                "ALERTING_PROFILE" | translate
                            }}</label>
                            <zx-alerting-profile-select
                                name="alertingProfile"
                                [(model)]="target.alertingProfile"
                                [ngClass]="{ 'is-invalid': form.submitted && form.controls.alertingProfile?.errors }"
                            ></zx-alerting-profile-select>
                        </div>
                        <!-- Location -->
                        <div class="form-group">
                            <label for="location_text"
                                >{{ "LOCATION" | translate
                                }}<fa-icon icon="info-circle" [ngbTooltip]="LocationContent" triggers="hover click" [closeDelay]="500"></fa-icon>
                                <ng-template #LocationContent>{{ "TOOLTIP.TARGET_LOCATION" | translate }}</ng-template></label
                            >
                            <zx-location-search [(model)]="target.location.address"></zx-location-search>
                        </div>
                    </section>

                    <section id="config">
                        <div class="form-section-title">
                            <h3 class="section-title" title="{{ 'CONFIGURATION' | translate }}">
                                {{ "CONFIGURATION" | translate }}
                            </h3>
                        </div>
                        <!-- Channel Select -->
                        <div class="form-group">
                            <label for="delivery_channel_select">
                                {{ "CHANNEL" | translate
                                }}<fa-icon icon="info-circle" [ngbTooltip]="COContent" triggers="hover click" [closeDelay]="500"></fa-icon
                                ><ng-template #COContent
                                    ><p class="mb-0">
                                        {{ "TOOLTIP.TARGET_CHANNEL_OPTIONAL" | translate }}
                                    </p></ng-template
                                ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                            </label>
                            <zx-delivery-channel-select
                                id="delivery_channel_select"
                                name="delivery_channel_select"
                                [(model)]="selectedChannelID"
                                [clearable]="true"
                                [failover]="true"
                            ></zx-delivery-channel-select>
                        </div>
                        <!-- Source preference -->
                        <div class="form-group">
                            <label for="source_preference">
                                {{ "SOURCE_PREFERENCE" | translate }}
                            </label>
                            <zx-delivery-channel-source-select
                                id="preferred_source"
                                name="preferred_source"
                                [(channel_id)]="selectedChannelID"
                                [(model)]="target.preferred_source"
                                [disabled]="!selectedChannelID"
                            >
                            </zx-delivery-channel-source-select>
                        </div>
                    </section>
                    <!-- State -->
                    <section id="state" *ngIf="!isEdit">
                        <div class="form-section-title">
                            <h3 class="section-title" title="{{ 'INITIAL_STATE' | translate }}">
                                {{ "INITIAL_STATE" | translate }}
                            </h3>
                        </div>
                        <!-- Enabled -->
                        <div class="form-group">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="is_enabled" name="is_enabled" [(ngModel)]="startDisabled" />
                                <label class="form-check-label" for="is_enabled">{{ "DISABLED" | translate }}</label>
                            </div>
                        </div>
                        <!-- Muted -->
                        <div class="form-group">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="muted" name="muted" [(ngModel)]="target.muted" />
                                <label class="form-check-label" for="muted">{{ "MUTED" | translate }}</label>
                            </div>
                        </div>
                    </section>
                    <app-error [marginBottom]="true" [marginTop]="false"></app-error>
                    <hr class="mt-0" />
                    <div class="text-end">
                        <button type="button" class="btn btn-outline-secondary float-start" (click)="back()" *ngIf="!isEdit && !isClone" [disabled]="saving">
                            <fa-icon icon="chevron-left" size="sm"></fa-icon>
                            {{ "BACK" | translate }}
                        </button>
                        <button type="button" class="btn btn-outline-secondary" (click)="cancel()" [disabled]="saving">
                            <fa-icon icon="times"></fa-icon>
                            {{ "CANCEL" | translate }}
                        </button>
                        <button
                            zmid="form-submit"
                            type="submit"
                            class="btn btn-primary ms-2"
                            [disabled]="saving"
                            [ngClass]="{ 'btn-danger': form.submitted && form.invalid }"
                        >
                            <fa-icon icon="check" size="sm"></fa-icon>
                            {{ "SAVE" | translate }}<span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

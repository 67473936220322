<content-loading-animation *ngIf="loading"></content-loading-animation>
<form id="form" #form="ngForm" (ngSubmit)="form.valid && !nameControl.invalid && !tagsControl.invalid && onSubmit(form)" *ngIf="!loading">
    <div class="title-bar">
        <div class="row justify-content-center">
            <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                <div class="title">
                    <h1 *ngIf="!isClone && !isEdit">
                        {{ "CREATE_NEW_MEDIALIVE_HTTP_TARGET" | translate }}
                    </h1>
                    <h1 *ngIf="isClone">{{ "CLONE_MEDIALIVE_HTTP_TARGET" | translate }}</h1>
                    <h1 *ngIf="isEdit">{{ "EDIT_MEDIALIVE_HTTP_TARGET" | translate }}</h1>
                </div>
            </div>
        </div>
    </div>
    <div class="content-area">
        <div class="container-fluid">
            <!-- HTTP -->
            <div class="row justify-content-center">
                <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                    <section id="info">
                        <div class="form-section-title">
                            <h3 class="section-title" title="{{ 'INFO' | translate }}">
                                {{ "INFO" | translate }}
                            </h3>
                        </div>

                        <!-- Name -->
                        <app-input-generic-field
                            zmid="form_name"
                            [isRestartRequiredTip]="isEdit"
                            [duplicateName]="targetNames"
                            [formControl]="nameControl"
                            [textFieldName]="'NAME' | translate"
                            [disabled]="saving"
                            [isParentFormSubmitted]="form.submitted"
                            type="text"
                        ></app-input-generic-field>

                        <!-- Access Tags -->
                        <zx-access-tags-select
                            zmid="form_access-tags"
                            [formControl]="tagsControl"
                            [label]="'TAGS' | translate"
                            name="resource_tag_ids"
                            type="adaptive"
                            [isParentFormSubmitted]="form.submitted"
                        ></zx-access-tags-select>
                        <!-- Alerting Profile -->
                        <div class="form-group">
                            <label for="alertingProfile" [ngClass]="{ 'is-invalid': form.submitted && form.controls.alertingProfile?.errors }">{{
                                "ALERTING_PROFILE" | translate
                            }}</label>
                            <zx-alerting-profile-select
                                name="alertingProfile"
                                [(model)]="target.alertingProfile"
                                [ngClass]="{ 'is-invalid': form.submitted && form.controls.alertingProfile?.errors }"
                            ></zx-alerting-profile-select>
                        </div>
                    </section>

                    <section id="config">
                        <div class="form-section-title">
                            <h3 class="section-title" title="{{ 'CONFIGURATION' | translate }}">
                                {{ "CONFIGURATION" | translate }}
                            </h3>
                        </div>
                        <!-- Channel Select -->
                        <div class="form-group">
                            <label for="medialive_channel_select">
                                {{ "CHANNEL" | translate
                                }}<fa-icon icon="info-circle" [ngbTooltip]="COContent" triggers="hover click" [closeDelay]="500"></fa-icon
                                ><ng-template #COContent
                                    ><p class="mb-0">
                                        {{ "TOOLTIP.TARGET_CHANNEL_OPTIONAL" | translate }}
                                    </p></ng-template
                                ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                            </label>
                            <zx-medialive-channel-select
                                id="medialive_channel_select"
                                name="medialive_channel_select"
                                [(model)]="selectedChannelID"
                                [clearable]="true"
                            ></zx-medialive-channel-select>
                        </div>
                        <!-- Type -->
                        <fieldset class="form-group">
                            <legend for="type">
                                {{ "TYPE" | translate
                                }}<fa-icon icon="lock" size="sm" [ngbTooltip]="TypeContent" triggers="hover click" [closeDelay]="500"></fa-icon
                                ><ng-template #TypeContent
                                    ><p class="mb-0">
                                        {{ "TOOLTIP.TARGET_TYPE" | translate }}
                                    </p></ng-template
                                >
                            </legend>
                            <mat-button-toggle-group name="type" aria-label="type" [(ngModel)]="target.destination">
                                <mat-button-toggle value="s3" [disabled]="isEdit || isClone">{{ "S3_BUCKET" | translate }}</mat-button-toggle>
                                <mat-button-toggle value="mediastore" [disabled]="isEdit || isClone">{{ "MEDIASTORE" | translate }}</mat-button-toggle>
                                <mat-button-toggle value="http" [disabled]="isEdit || isClone">{{ "HTTP_SERVER" | translate }}</mat-button-toggle>
                            </mat-button-toggle-group>
                        </fieldset>

                        <!-- S3 URI -->
                        <div class="form-group" *ngIf="target.destination === 's3'">
                            <label for="s3_uri" [ngClass]="{ 'is-invalid': form.submitted && s3_uri.errors }"
                                >{{ "S3_URI" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                            ></label>
                            <input
                                type="text"
                                id="s3_uri"
                                name="s3_uri"
                                placeholder="{{ 'S3_URI' | translate }}"
                                class="form-control"
                                [ngClass]="{ 'is-invalid': form.submitted && s3_uri.errors }"
                                [(ngModel)]="target.ingest_url"
                                required
                                duplicateName="{{ existingURLs }}"
                                pattern="^(s3|s3ssl):\/\/[a-z0-9][a-z0-9\.-]{1,61}[a-z0-9]\/.*"
                                #s3_uri="ngModel"
                            />
                            <div *ngIf="s3_uri.invalid" class="invalid-feedback">
                                <div *ngIf="s3_uri.errors.required">{{ "S3_URI" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                <div *ngIf="s3_uri.errors.pattern">{{ "S3_URI" | translate }} {{ "INVALID" | translate }}.</div>
                                <div *ngIf="s3_uri.errors.duplicateName">{{ "S3_URI" | translate }} {{ "MUST_BE_UNIQUE" | translate }}.</div>
                            </div>
                            <small>e.g. s3://mybucketname/my/path/manifest</small>
                        </div>
                        <!-- MediaStore URI -->
                        <div class="form-group" *ngIf="target.destination === 'mediastore'">
                            <label for="mediastore_uri" [ngClass]="{ 'is-invalid': form.submitted && mediastore_uri.errors }"
                                >{{ "MEDIASTORE_URI" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                            ></label>
                            <input
                                type="text"
                                id="mediastore_uri"
                                name="mediastore_uri"
                                placeholder="{{ 'MEDIASTORE_URI' | translate }}"
                                class="form-control"
                                [ngClass]="{ 'is-invalid': form.submitted && mediastore_uri.errors }"
                                [(ngModel)]="target.ingest_url"
                                required
                                duplicateName="{{ existingURLs }}"
                                pattern="^(mediastore|mediastoressl):\/\/.*\.data\.mediastore\.[a-z0-9-]+\.amazonaws\.com\/.*"
                                #mediastore_uri="ngModel"
                            />
                            <div *ngIf="mediastore_uri.invalid" class="invalid-feedback">
                                <div *ngIf="mediastore_uri.errors.required">{{ "MEDIASTORE_URI" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                <div *ngIf="mediastore_uri.errors.pattern">{{ "MEDIASTORE_URI" | translate }} {{ "INVALID" | translate }}.</div>
                                <div *ngIf="mediastore_uri.errors.duplicateName">{{ "MEDIASTORE_URI" | translate }} {{ "MUST_BE_UNIQUE" | translate }}.</div>
                            </div>
                            <small>e.g. mediastore://example.data.mediastore.us-east-1.amazonaws.com/path/manifest</small>
                        </div>
                        <!-- HTTP URI -->
                        <div class="form-group" *ngIf="target.destination === 'http'">
                            <label for="http_uri" [ngClass]="{ 'is-invalid': form.submitted && http_uri.errors }"
                                >{{ "HTTP_URI" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                            ></label>
                            <input
                                type="text"
                                id="http_uri"
                                name="http_uri"
                                placeholder="{{ 'HTTP_URI' | translate }}"
                                class="form-control"
                                [ngClass]="{ 'is-invalid': form.submitted && http_uri.errors }"
                                [(ngModel)]="target.ingest_url"
                                required
                                duplicateName="{{ existingURLs }}"
                                pattern="^(http|https):\/\/.*"
                                #http_uri="ngModel"
                            />
                            <div *ngIf="http_uri.invalid" class="invalid-feedback">
                                <div *ngIf="http_uri.errors.required">{{ "HTTP_URI" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                <div *ngIf="http_uri.errors.pattern">{{ "HTTP_URI" | translate }} {{ "INVALID" | translate }}.</div>
                                <div *ngIf="http_uri.errors.duplicateName">{{ "HTTP_URI" | translate }} {{ "MUST_BE_UNIQUE" | translate }}.</div>
                            </div>
                            <small>e.g. https://hls.example.com/path</small>
                        </div>
                        <!-- Transcoding Profiles -->
                        <div class="form-group">
                            <label for="transcoding_profiles" [ngClass]="{ 'is-invalid': form.submitted && selectedBitrates.length === 0 }"
                                >{{ "RENDITIONS" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                            ></label>
                            <div class="table-responsive">
                                <table class="table table-sm bordered m-0" [ngClass]="{ 'is-invalid': form.submitted && selectedBitrates.length === 0 }">
                                    <thead>
                                        <tr>
                                            <th scope="col">{{ "TRANSCODING_PROFILE" | translate }}</th>
                                            <th scope="col">{{ "VIDEO" | translate }}</th>
                                            <th scope="col">{{ "VIDEO_CODEC" | translate }}</th>
                                            <th scope="col">{{ "AUDIO" | translate }}</th>
                                            <th scope="col">{{ "AUDIO_CODEC" | translate }}</th>
                                            <th scope="col" class="w-50px text-end">
                                                <fa-icon
                                                    class=""
                                                    icon="info-circle"
                                                    [ngbTooltip]="TPIContent"
                                                    triggers="hover click"
                                                    [closeDelay]="500"
                                                ></fa-icon>
                                                <ng-template #TPIContent>
                                                    <p class="mb-0">{{ "TOOLTIP.CHANNEL_TRANSCODING_PROFILES_INSTRUCTIONS" | translate }}</p>
                                                </ng-template>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="!profilesLoading">
                                        <tr *ngFor="let bitrate of selectedBitrates; index as index">
                                            <td title="{{ bitrate.profile.name }}" class="maxw-80px ellipsis">
                                                {{ bitrate.profile.name }}
                                            </td>
                                            <td
                                                title="{{
                                                    bitrate.profile.do_video && !bitrate.profile.source_resolution
                                                        ? bitrate.profile.width + 'x' + bitrate.profile.height
                                                        : bitrate.profile.keep_video || bitrate.profile.source_resolution
                                                        ? ('ORIGINAL' | translate)
                                                        : ('REMOVE' | translate)
                                                }}{{
                                                    bitrate.profile.do_video && !bitrate.profile.keep_video
                                                        ? ' @ ' + (bitrate.profile.bitrate_avg | number : '1.0-0') + ' kbps'
                                                        : ''
                                                }}"
                                                class="maxw-80px ellipsis"
                                            >
                                                {{
                                                    bitrate.profile.do_video && !bitrate.profile.source_resolution
                                                        ? bitrate.profile.width + "x" + bitrate.profile.height
                                                        : bitrate.profile.keep_video || bitrate.profile.source_resolution
                                                        ? ("ORIGINAL" | translate)
                                                        : ("REMOVE" | translate)
                                                }}
                                                {{
                                                    bitrate.profile.do_video && !bitrate.profile.keep_video
                                                        ? " @ " + (bitrate.profile.bitrate_avg | number : "1.0-0") + " kbps"
                                                        : ""
                                                }}
                                            </td>
                                            <td
                                                title="{{
                                                    bitrate.profile.do_video
                                                        ? bitrate.profile.video_codec === 'h265'
                                                            ? ('H.265' | translate)
                                                            : ('H.264' | translate)
                                                        : '-'
                                                }}"
                                                class="ellipsis"
                                            >
                                                {{
                                                    bitrate.profile.do_video
                                                        ? bitrate.profile.video_codec === "h265"
                                                            ? ("H.265" | translate)
                                                            : ("H.264" | translate)
                                                        : "-"
                                                }}
                                            </td>
                                            <td
                                                title="{{
                                                    bitrate.profile.do_audio
                                                        ? (bitrate.profile.audio_bitrate | number : '1.0-0') + ' kbps'
                                                        : bitrate.profile.keep_audio
                                                        ? ('ORIGINAL' | translate)
                                                        : ('REMOVE' | translate)
                                                }}"
                                                class="maxw-80px ellipsis"
                                            >
                                                {{
                                                    bitrate.profile.do_audio
                                                        ? (bitrate.profile.audio_bitrate | number : "1.0-0") + " kbps"
                                                        : bitrate.profile.keep_audio
                                                        ? ("ORIGINAL" | translate)
                                                        : ("REMOVE" | translate)
                                                }}
                                            </td>
                                            <td
                                                title="{{
                                                    bitrate.profile.do_audio
                                                        ? bitrate.profile.audio_codec === 'aac'
                                                            ? ('AAC' | translate)
                                                            : bitrate.profile.audio_codec === 'mpga1'
                                                            ? ('SHORT_MPGA' | translate)
                                                            : ('AC3' | translate)
                                                        : '-'
                                                }}"
                                                class="ellipsis"
                                            >
                                                {{
                                                    bitrate.profile.do_audio
                                                        ? bitrate.profile.audio_codec === "aac"
                                                            ? ("AAC" | translate)
                                                            : bitrate.profile.audio_codec === "mpga1"
                                                            ? ("SHORT_MPGA" | translate)
                                                            : ("AC3" | translate)
                                                        : "-"
                                                }}
                                            </td>
                                            <td class="text-end">
                                                <button
                                                    type="button"
                                                    class="btn btn-sm btn-xs btn-round-xs btn-outline-secondary"
                                                    (click)="deselectTranscodedBitrate(bitrate)"
                                                >
                                                    <fa-icon icon="minus" size="sm"></fa-icon>
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td [attr.colspan]="multiGpu ? 6 : 5">
                                                <div class="form-inline">
                                                    <div class="form-group stay-inline">
                                                        <label for="profilesFilter">{{ "FILTER" | translate }}:</label>
                                                        <input
                                                            type="text"
                                                            name="profilesFilter"
                                                            class="ms-1 form-control form-control-sm form-control-xs maxw-140px"
                                                            [(ngModel)]="profilesFilter"
                                                        />
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr
                                            *ngFor="
                                                let profile of profiles
                                                    | filter : ['profile'] : profilesFilter
                                                    | slice : (page - 1) * pageSize : (page - 1) * pageSize + pageSize
                                            "
                                            class="cursor-pointer row-hover"
                                            (click)="selectTranscodedBitrate(profile)"
                                        >
                                            <td class="maxw-80px ellipsis">
                                                <ngb-highlight title="{{ profile.name }}" [result]="profile.name" [term]="profilesFilter"></ngb-highlight>
                                            </td>
                                            <td
                                                title="{{
                                                    profile.do_video && !profile.source_resolution
                                                        ? profile.width + 'x' + profile.height
                                                        : profile.keep_video || profile.source_resolution
                                                        ? ('ORIGINAL' | translate)
                                                        : ('REMOVE' | translate)
                                                }}{{
                                                    profile.do_video && !profile.keep_video ? ' @ ' + (profile.bitrate_avg | number : '1.0-0') + ' kbps' : ''
                                                }}"
                                                class="maxw-80px ellipsis"
                                            >
                                                {{
                                                    profile.do_video && !profile.source_resolution
                                                        ? profile.width + "x" + profile.height
                                                        : profile.keep_video || profile.source_resolution
                                                        ? ("ORIGINAL" | translate)
                                                        : ("REMOVE" | translate)
                                                }}
                                                {{ profile.do_video && !profile.keep_video ? " @ " + (profile.bitrate_avg | number : "1.0-0") + " kbps" : "" }}
                                            </td>
                                            <td
                                                title="{{
                                                    profile.do_video ? (profile.video_codec === 'h265' ? ('H.265' | translate) : ('H.264' | translate)) : '-'
                                                }}"
                                                class="ellipsis"
                                            >
                                                {{ profile.do_video ? (profile.video_codec === "h265" ? ("H.265" | translate) : ("H.264" | translate)) : "-" }}
                                            </td>
                                            <td
                                                title="{{
                                                    profile.do_audio
                                                        ? (profile.audio_bitrate | number : '1.0-0') + ' kbps'
                                                        : profile.keep_audio
                                                        ? ('ORIGINAL' | translate)
                                                        : ('REMOVE' | translate)
                                                }}"
                                                class="maxw-80px ellipsis"
                                            >
                                                {{
                                                    profile.do_audio
                                                        ? (profile.audio_bitrate | number : "1.0-0") + " kbps"
                                                        : profile.keep_audio
                                                        ? ("ORIGINAL" | translate)
                                                        : ("REMOVE" | translate)
                                                }}
                                            </td>
                                            <td
                                                title="{{
                                                    profile.do_audio
                                                        ? profile.audio_codec === 'aac'
                                                            ? ('AAC' | translate)
                                                            : profile.audio_codec === 'mpga1'
                                                            ? ('SHORT_MPGA' | translate)
                                                            : ('AC3' | translate)
                                                        : '-'
                                                }}"
                                                class="ellipsis"
                                            >
                                                {{
                                                    profile.do_audio
                                                        ? profile.audio_codec === "aac"
                                                            ? ("AAC" | translate)
                                                            : profile.audio_codec === "mpga1"
                                                            ? ("SHORT_MPGA" | translate)
                                                            : ("AC3" | translate)
                                                        : "-"
                                                }}
                                            </td>
                                            <td class="text-end">
                                                <button
                                                    type="button"
                                                    class="btn btn-sm btn-xs btn-round-xs btn-outline-primary"
                                                    (click)="selectTranscodedBitrate(profile)"
                                                >
                                                    <fa-icon icon="plus" size="sm"></fa-icon>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="profilesLoading">
                                        <tr>
                                            <td colspan="5" class="text-center">{{ "LOADING" | translate }}...</td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="!profilesLoading && (profiles | filter : ['profile'] : profilesFilter).length === 0">
                                        <tr>
                                            <td colspan="5" class="text-center">{{ "NO_TRANSCODING_PROFILES" | translate }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div *ngIf="form.submitted && selectedBitrates.length === 0" class="invalid-feedback">
                                {{ "AT_LEAST_1_PROFILE_IS_REQUIRED" | translate }}.
                            </div>
                            <div class="mt-2" *ngIf="profiles && (profiles | filter : ['profile'] : profilesFilter).length > pageSize">
                                <ngb-pagination
                                    class="mb-0"
                                    [collectionSize]="(profiles | filter : ['profile'] : profilesFilter).length"
                                    [(page)]="page"
                                    [pageSize]="pageSize"
                                    [maxSize]="2"
                                ></ngb-pagination>
                            </div>
                        </div>
                    </section>

                    <!-- State -->
                    <section id="state" *ngIf="!isEdit">
                        <div class="form-section-title">
                            <h3 class="section-title" title="{{ 'INITIAL_STATE' | translate }}">
                                {{ "INITIAL_STATE" | translate }}
                            </h3>
                        </div>
                        <!-- Enabled -->
                        <div class="form-group">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="is_enabled" name="is_enabled" [(ngModel)]="startDisabled" />
                                <label class="form-check-label" for="is_enabled">{{ "DISABLED" | translate }}</label>
                            </div>
                        </div>
                        <!-- Muted -->
                        <div class="form-group">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="muted" name="muted" [(ngModel)]="target.muted" />
                                <label class="form-check-label" for="muted">{{ "MUTED" | translate }}</label>
                            </div>
                        </div>
                    </section>
                    <app-error [marginBottom]="true" [marginTop]="false"></app-error>
                    <hr class="mt-0" />
                    <div class="text-end">
                        <button type="button" class="btn btn-outline-secondary float-start" (click)="back()" *ngIf="!isEdit && !isClone" [disabled]="saving">
                            <fa-icon icon="chevron-left" size="sm"></fa-icon>
                            {{ "BACK" | translate }}
                        </button>
                        <button type="button" class="btn btn-outline-secondary" (click)="cancel()" [disabled]="saving">
                            <fa-icon icon="times"></fa-icon>
                            {{ "CANCEL" | translate }}
                        </button>
                        <button zmid="form-submit" type="submit" class="btn btn-primary ms-2" [disabled]="saving">
                            <fa-icon icon="check" size="sm"></fa-icon>
                            {{ "SAVE" | translate }}<span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

<content-loading-animation *ngIf="loading"></content-loading-animation>
<form id="form" #form="ngForm" (ngSubmit)="form.valid && !nameControl.invalid && !tagsControl.invalid && onSubmit(form)" *ngIf="!loading">
    <div class="title-bar">
        <div class="row justify-content-center">
            <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                <div class="title">
                    <h1 *ngIf="!isClone && !isEdit">
                        {{ "CREATE_NEW_JPEGXS_TARGET" | translate }}
                    </h1>
                    <h1 *ngIf="isClone">{{ "CLONE_JPEGXS_TARGET" | translate }}</h1>
                    <h1 *ngIf="isEdit">{{ "EDIT_JPEGXS_TARGET" | translate }}</h1>
                </div>
            </div>
        </div>
    </div>
    <div class="content-area">
        <div class="container-fluid">
            <!-- JPEGXS -->
            <div class="row justify-content-center">
                <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                    <section id="info">
                        <div class="form-section-title">
                            <h3 class="section-title" title="{{ 'INFO' | translate }}">
                                {{ "INFO" | translate }}
                            </h3>
                        </div>

                        <!-- Name -->
                        <app-input-generic-field
                            zmid="form_name"
                            [isRestartRequiredTip]="isEdit"
                            [duplicateName]="targetNames"
                            [formControl]="nameControl"
                            [textFieldName]="'NAME' | translate"
                            [disabled]="saving"
                            [isParentFormSubmitted]="form.submitted"
                            type="text"
                        ></app-input-generic-field>

                        <!-- Access Tags -->
                        <zx-access-tags-select
                            zmid="form_access-tags"
                            [formControl]="tagsControl"
                            [label]="'TAGS' | translate"
                            name="resource_tag_ids"
                            type="delivery"
                            [isParentFormSubmitted]="form.submitted"
                        ></zx-access-tags-select>
                        <!-- Alerting Profile -->
                        <div class="form-group">
                            <label for="alertingProfile" [ngClass]="{ 'is-invalid': form.submitted && form.controls.alertingProfile?.errors }">{{
                                "ALERTING_PROFILE" | translate
                            }}</label>
                            <zx-alerting-profile-select
                                name="alertingProfile"
                                [(model)]="target.alertingProfile"
                                [ngClass]="{ 'is-invalid': form.submitted && form.controls.alertingProfile?.errors }"
                            ></zx-alerting-profile-select>
                        </div>
                    </section>

                    <section id="config">
                        <div class="form-section-title">
                            <h3 class="section-title" title="{{ 'CONFIGURATION' | translate }}">
                                {{ "CONFIGURATION" | translate }}
                            </h3>
                        </div>
                        <!-- Channel Select -->
                        <div class="form-group">
                            <label for="mediaconnect_flow_select"
                                >{{ "AWS_MEDIACONNECT_FLOW" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                            ></label>
                            <zx-mediaconnect-flow-select
                                name="mediaconnect_flow_select"
                                [(model)]="selectedChannelID"
                                [clearable]="true"
                                [filter]="JPEGXSCompatibleFlow()"
                                (modelChange)="flowSelected()"
                            ></zx-mediaconnect-flow-select>
                            <small translate>JPEGXS_TARGET_REQUIREMENTS</small>
                        </div>

                        <!-- Media Streams -->
                        <div
                            class="form-section-title"
                            *ngIf="channel"
                            [ngClass]="{
                                'is-invalid': form.submitted && channel && mediaStreamSelectionProblem
                            }"
                        >
                            <h3
                                class="section-title mb-0"
                                title="{{ 'MEDIA_STREAMS' | translate }}"
                                [ngClass]="{
                                    'is-invalid': form.submitted && channel && mediaStreamSelectionProblem
                                }"
                            >
                                {{ "MEDIA_STREAMS" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                            </h3>
                        </div>
                        <div *ngIf="form.submitted && channel && mediaStreamSelectionProblem" class="invalid-feedback">
                            {{ "MEDIA_STREAMS" | translate }} {{ "ARE_REQUIRED" | translate }}
                        </div>
                        <p *ngIf="!mediaStreams.length && channel">{{ "NO_MEDIA_STREAMS" | translate }}</p>
                        <div *ngFor="let mediaStream of mediaStreams; index as index">
                            <zx-mediaconnect-mediastream
                                id="mediastream_{{ index }}"
                                name="mediastream_{{ index }}"
                                [(ngModel)]="mediaStreams[index]"
                                [model]="mediaStreams[index]"
                                [channel]="channel"
                                [source]="selectedSource"
                                [jpegXSOutput]="true"
                                [sourceSelection]="false"
                                [outputSelection]="true"
                                [removeAllowed]="false"
                                [isEdit]="true"
                                [collapsed]="true"
                                [isParentFormSubmitted]="form.submitted"
                                [streamNames]="[]"
                                [streamIDs]="[]"
                                [videoCount]="0"
                                [dataCount]="0"
                                [streamPorts]="mediaStreamPorts"
                                (propertiesChange)="mediaStreamConfigChanged()"
                            ></zx-mediaconnect-mediastream>
                        </div>

                        <!-- VPCs -->
                        <div
                            class="form-section-title"
                            *ngIf="channel"
                            [ngClass]="{
                                'is-invalid': form.submitted && channel && VPCSelectionProblem
                            }"
                        >
                            <h3
                                class="section-title mb-0"
                                title="{{ 'VPC_INTERFACES' | translate }}"
                                [ngClass]="{
                                    'is-invalid': form.submitted && channel && VPCSelectionProblem
                                }"
                            >
                                {{ "VPC_INTERFACES" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                            </h3>
                        </div>
                        <div *ngIf="form.submitted && channel && VPCSelectionProblem" class="invalid-feedback">
                            {{ "AT_LEAST" | translate }} 2 {{ "VPC_INTERFACES" | translate }} {{ "ARE_REQUIRED" | translate }}
                        </div>
                        <p *ngIf="!vpc.length && channel">{{ "NO_VPC_INTERFACES" | translate }}</p>
                        <div *ngFor="let v of vpc; index as index">
                            <zx-mediaconnect-vpc
                                id="vpc_{{ index }}"
                                name="vpc_{{ index }}"
                                [(ngModel)]="vpc[index]"
                                [model]="vpc[index]"
                                [channel]="channel"
                                [jpegXSOutput]="true"
                                [sourceSelection]="false"
                                [outputSelection]="true"
                                [removeAllowed]="false"
                                [readOnly]="true"
                                [collapsed]="true"
                                [awsIAMRoles]="awsIAMRoles"
                                [awsIAMRolesLoading]="awsIAMRolesLoading"
                                [awsAccountZonesLoading]="awsAccountZonesLoading"
                                [awsSubnets]="awsSubnets"
                                [awsSubnetOptions]="awsSubnetOptions"
                                [awsSecurityGroups]="awsSecurityGroups"
                                [isParentFormSubmitted]="false"
                                [propertiesChange]="VPCCompositionChanged()"
                            ></zx-mediaconnect-vpc>
                        </div>
                    </section>

                    <!-- State -->
                    <section id="state" *ngIf="!isEdit">
                        <div class="form-section-title">
                            <h3 class="section-title" title="{{ 'INITIAL_STATE' | translate }}">
                                {{ "INITIAL_STATE" | translate }}
                            </h3>
                        </div>
                        <!-- Enabled -->
                        <div class="form-group">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="is_enabled" name="is_enabled" [(ngModel)]="startDisabled" />
                                <label class="form-check-label" for="is_enabled">{{ "DISABLED" | translate }}</label>
                            </div>
                        </div>
                        <!-- Muted -->
                        <div class="form-group">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="muted" name="muted" [(ngModel)]="target.muted" />
                                <label class="form-check-label" for="muted">{{ "MUTED" | translate }}</label>
                            </div>
                        </div>
                    </section>

                    <app-error [marginBottom]="true" [marginTop]="false"></app-error>
                    <hr class="mt-0" />
                    <div class="text-end">
                        <button type="button" class="btn btn-outline-secondary float-start" (click)="back()" *ngIf="!isEdit && !isClone" [disabled]="saving">
                            <fa-icon icon="chevron-left" size="sm"></fa-icon>
                            {{ "BACK" | translate }}
                        </button>
                        <button type="button" class="btn btn-outline-secondary" (click)="cancel()" [disabled]="saving">
                            <fa-icon icon="times"></fa-icon>
                            {{ "CANCEL" | translate }}
                        </button>
                        <button
                            zmid="form-submit"
                            type="submit"
                            class="btn btn-primary ms-2"
                            [disabled]="saving"
                            [ngClass]="{ 'btn-danger': form.submitted && form.invalid }"
                        >
                            <fa-icon icon="check" size="sm"></fa-icon>
                            {{ "SAVE" | translate }}<span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
